import {
  BlockTag,
  EventType,
  TransactionReceipt
} from '@ethersproject/abstract-provider';
import { BigNumberish } from '@ethersproject/bignumber';

import {
  ERC1155Metadata,
  NftRefreshState,
  NftTokenType,
  RawContract
} from './nft-types';

export * from './ethers-types';

// TODO: separate this file into other files.

/**
 * Options object used to configure the Alchemy SDK.
 *
 * @public
 */
export interface AlchemySettings {
  /**
   * The Alchemy API key that can be found in the Alchemy dashboard.
   *
   * Defaults to: "demo" (a rate-limited public API key)
   */
  apiKey?: string;

  /**
   * The name of the network. Once configured, the network cannot be changed. To
   * use a different network, instantiate a new `Alchemy` instance.
   *
   * Defaults to: Network.ETH_MAINNET
   */
  network?: Network;

  /** The maximum number of retries to attempt if a request fails. Defaults to 5. */
  maxRetries?: number;

  /**
   * Optional URL endpoint to use for all requests. Setting this field will
   * override the URL generated by the {@link network} and {@link apiKey} fields.
   *
   * This field is useful for testing or for using a custom node endpoint. Note
   * that not all methods will work with custom URLs.
   */
  url?: string;

  /**
   * Alchemy auth token required to use the Notify API. This token can be found
   * in the Alchemy Dashboard on the Notify tab.
   */
  authToken?: string;

  /**
   * Optional Request timeout provided in `ms` while using NFT and NOTIFY API.
   * Default to 0 (No timeout).
   */
  requestTimeout?: number;

  /**
   * Optional setting that automatically batches and sends json-rpc requests for
   * higher throughput and reduced network IO. Defaults to false.
   *
   * This implementation is based on the `JsonRpcBatchProvider` in ethers.
   */
  batchRequests?: boolean;
}

/**
 * The supported networks by Alchemy. Note that some functions are not available
 * on all networks. Please refer to the Alchemy documentation for which APIs are
 * available on which networks
 * {@link https://docs.alchemy.com/alchemy/apis/feature-support-by-chain}
 *
 * @public
 */
export enum Network {
  ETH_MAINNET = 'eth-mainnet',
  ETH_GOERLI = 'eth-goerli',
  ETH_SEPOLIA = 'eth-sepolia',
  OPT_MAINNET = 'opt-mainnet',
  OPT_GOERLI = 'opt-goerli',
  OPT_SEPOLIA = 'opt-sepolia',
  ARB_MAINNET = 'arb-mainnet',
  ARB_GOERLI = 'arb-goerli',
  ARB_SEPOLIA = 'arb-sepolia',
  MATIC_MAINNET = 'polygon-mainnet',
  MATIC_MUMBAI = 'polygon-mumbai',
  ASTAR_MAINNET = 'astar-mainnet',
  POLYGONZKEVM_MAINNET = 'polygonzkevm-mainnet',
  POLYGONZKEVM_TESTNET = 'polygonzkevm-testnet',
  BASE_MAINNET = 'base-mainnet',
  BASE_GOERLI = 'base-goerli',
  BASE_SEPOLIA = 'base-sepolia'
}

/** Token Types for the `getTokenBalances()` endpoint. */
export enum TokenBalanceType {
  /**
   * Option to fetch the top 100 tokens by 24-hour volume. This option is only
   * available on Mainnet in Ethereum, Polygon, and Arbitrum.
   */
  DEFAULT_TOKENS = 'DEFAULT_TOKENS',

  /**
   * Option to fetch the set of ERC-20 tokens that the address as ever held. his
   * list is produced by an address's historical transfer activity and includes
   * all tokens that the address has ever received.
   */
  ERC20 = 'erc20'
}

/**
 * Optional params to pass into `getTokenBalances()` to fetch all ERC-20 tokens
 * instead of passing in an array of contract addresses to fetch balances for.
 */
export interface TokenBalancesOptionsErc20 {
  /** The ERC-20 token type. */
  type: TokenBalanceType.ERC20;

  /** Optional page key for pagination (only applicable to TokenBalanceType.ERC20) */
  pageKey?: string;
}

/**
 * Optional params to pass into `getTokenBalances()` to fetch the top 100 tokens
 * instead of passing in an array of contract addresses to fetch balances for.
 */
export interface TokenBalancesOptionsDefaultTokens {
  /** The top 100 token type. */
  type: TokenBalanceType.DEFAULT_TOKENS;
}

/**
 * Response object for when the {@link TokenBalancesOptionsErc20} options are
 * used. A page key may be returned if the provided address has many transfers.
 */
export interface TokenBalancesResponseErc20 extends TokenBalancesResponse {
  /**
   * An optional page key to passed into the next request to fetch the next page
   * of token balances.
   */
  pageKey?: string;
}

/** @public */
export interface TokenBalancesResponse {
  address: string;
  tokenBalances: TokenBalance[];
}

/** @public */
export type TokenBalance = TokenBalanceSuccess | TokenBalanceFailure;

/** @public */
export interface TokenBalanceSuccess {
  contractAddress: string;
  tokenBalance: string;
  error: null;
}

/** @public */
export interface TokenBalanceFailure {
  contractAddress: string;
  tokenBalance: null;
  error: string;
}

/**
 * Optional params to pass into {@link CoreNamespace.getTokensForOwner}.
 */
export interface GetTokensForOwnerOptions {
  /**
   * List of contract addresses to filter by. If omitted, defaults to
   * {@link TokenBalanceType.ERC20}.
   */
  contractAddresses?: string[] | TokenBalanceType;
  /**
   * Optional page key from an existing {@link GetTokensForOwnerResponse} to use for
   * pagination.
   */
  pageKey?: string;
}

/**
 * Response object for {@link CoreNamespace.getTokensForOwner}.
 */
export interface GetTokensForOwnerResponse {
  /** Owned tokens for the provided addresses along with relevant metadata. */
  tokens: OwnedToken[];
  /** Page key for the next page of results, if one exists. */
  pageKey?: string;
}

/**
 * Represents an owned token on a {@link GetTokensForOwnerResponse}.
 */
export interface OwnedToken {
  /** The contract address of the token. */
  contractAddress: string;
  /**
   * The raw value of the balance field as a hex string. This value is undefined
   * if the {@link error} field is present.
   */
  rawBalance?: string;
  /**
   * The formatted value of the balance field as a hex string. This value is
   * undefined if the {@link error} field is present, or if the `decimals` field=
   * is undefined.
   */
  balance?: string;
  /** */
  /**
   * The token's name. Is undefined if the name is not defined in the contract and
   * not available from other sources.
   */
  name?: string;
  /**
   * The token's symbol. Is undefined if the symbol is not defined in the contract
   * and not available from other sources.
   */
  symbol?: string;
  /**
   * The number of decimals of the token. Is undefined if not defined in the
   * contract and not available from other sources.
   */
  decimals?: number;
  /** URL link to the token's logo. Is undefined if the logo is not available. */
  logo?: string;
  /**
   * Error from fetching the token balances. If this field is defined, none of
   * the other fields will be defined.
   */
  error?: string;
}

/**
 * Response object for the {@link CoreNamespace.getTokenMetadata} method.
 *
 * @public
 */
export interface TokenMetadataResponse {
  /**
   * The token's name. Is `null` if the name is not defined in the contract and
   * not available from other sources.
   */
  name: string | null;

  /**
   * The token's symbol. Is `null` if the symbol is not defined in the contract
   * and not available from other sources.
   */
  symbol: string | null;

  /**
   * The number of decimals of the token. Returns `null` if not defined in the
   * contract and not available from other sources.
   */
  decimals: number | null;

  /** URL link to the token's logo. Is `null` if the logo is not available. */
  logo: string | null;
}

/**
 * Parameters for the {@link CoreNamespace.getAssetTransfers} method.
 *
 * @public
 */
export interface AssetTransfersParams {
  /**
   * The starting block to check for transfers. This value is inclusive and
   * defaults to `0x0` if omitted.
   */
  fromBlock?: string;

  /**
   * The ending block to check for transfers. This value is inclusive and
   * defaults to the latest block if omitted.
   */
  toBlock?: string;

  /**
   * Whether to return results in ascending or descending order by block number.
   * Defaults to ascending if omitted.
   */
  order?: SortingOrder;

  /**
   * The from address to filter transfers by. This value defaults to a wildcard
   * for all addresses if omitted.
   */
  fromAddress?: string;

  /**
   * The to address to filter transfers by. This value defaults to a wildcard
   * for all address if omitted.
   */
  toAddress?: string;

  /**
   * List of contract addresses to filter for - only applies to "erc20",
   * "erc721", "erc1155" transfers. Defaults to all address if omitted.
   */
  contractAddresses?: string[];

  /**
   * Whether to exclude transfers with zero value. Note that zero value is
   * different than null value. Defaults to `true` if omitted.
   */
  excludeZeroValue?: boolean;

  /** REQUIRED field. An array of categories to get transfers for. */
  category: AssetTransfersCategory[];

  /** The maximum number of results to return per page. Defaults to 1000 if omitted. */
  maxCount?: number;

  /**
   * Optional page key from an existing {@link OwnedBaseNftsResponse}
   * {@link AssetTransfersResult}to use for pagination.
   */
  pageKey?: string;

  /**
   * Whether to include additional metadata about each transfer event. Defaults
   * to `false` if omitted.
   */
  withMetadata?: boolean;
}

/**
 * Parameters for the {@link CoreNamespace.getAssetTransfers} method that
 * includes metadata.
 *
 * @public
 */
export interface AssetTransfersWithMetadataParams extends AssetTransfersParams {
  withMetadata: true;
}

/**
 * Categories of transfers to use with the {@link AssetTransfersParams} request
 * object when using {@link CoreNamespace.getAssetTransfers}.
 *
 * @public
 */
export enum AssetTransfersCategory {
  /**
   * Top level ETH transactions that occur where the `fromAddress` is an
   * external user-created address. External addresses have private keys and are
   * accessed by users.
   */
  EXTERNAL = 'external',

  /**
   * Top level ETH transactions that occur where the `fromAddress` is an
   * internal, smart contract address. For example, a smart contract calling
   * another smart contract or sending
   */
  INTERNAL = 'internal',

  /** ERC20 transfers. */
  ERC20 = 'erc20',

  /** ERC721 transfers. */
  ERC721 = 'erc721',

  /** ERC1155 transfers. */
  ERC1155 = 'erc1155',

  /** Special contracts that don't follow ERC 721/1155, (ex: CryptoKitties). */
  SPECIALNFT = 'specialnft'
}

/**
 * Response object for the {@link CoreNamespace.getAssetTransfers} method.
 *
 * @public
 */
export interface AssetTransfersResponse {
  transfers: AssetTransfersResult[];
  /** Page key for the next page of results, if one exists. */
  pageKey?: string;
}

/**
 * Response object for the {@link CoreNamespace.getAssetTransfers} method when
 * the {@link AssetTransfersWithMetadataParams} are used.
 *
 * @public
 */
export interface AssetTransfersWithMetadataResponse {
  transfers: AssetTransfersWithMetadataResult[];
  pageKey?: string;
}

/**
 * Represents a transfer event that is returned in a {@link AssetTransfersResponse}.
 *
 * @public
 */
export interface AssetTransfersResult {
  /** The unique ID of the transfer. */
  uniqueId: string;

  /** The category of the transfer. */
  category: AssetTransfersCategory;

  /** The block number where the transfer occurred. */
  blockNum: string;

  /** The from address of the transfer. */
  from: string;

  /** The to address of the transfer. */
  to: string | null;

  /**
   * Converted asset transfer value as a number (raw value divided by contract
   * decimal). `null` if ERC721 transfer or contract decimal not available.
   */
  value: number | null;

  /**
   * The raw ERC721 token id of the transfer as a hex string. `null` if not an
   * ERC721 transfer.
   */
  erc721TokenId: string | null;

  /**
   * A list of ERC1155 metadata objects if the asset transferred is an ERC1155
   * token. `null` if not an ERC1155 transfer.
   */
  erc1155Metadata: ERC1155Metadata[] | null;

  /** The token id of the token transferred. */
  tokenId: string | null;

  /**
   * Returns the token's symbol or ETH for other transfers. `null` if the
   * information was not available.
   */
  asset: string | null;

  /** The transaction hash of the transfer transaction. */
  hash: string;

  /** Information about the raw contract of the asset transferred. */
  rawContract: RawContract;
}

/**
 * Represents a transfer event that is returned in a
 * {@link AssetTransfersResponse} when {@link AssetTransfersWithMetadataParams} are used.
 *
 * @public
 */
export interface AssetTransfersWithMetadataResult extends AssetTransfersResult {
  /** Additional metadata about the transfer event. */
  metadata: AssetTransfersMetadata;
}

/**
 * The metadata object for a {@link AssetTransfersResult} when the
 * {@link AssetTransfersParams.withMetadata} field is set to true.
 *
 * @public
 */
export interface AssetTransfersMetadata {
  /** Timestamp of the block from which the transaction event originated. */
  blockTimestamp: string;
}

/**
 * The type of transfer for the request. Note that using `TO` will also include
 * NFTs that were minted by the owner.
 */
export enum GetTransfersForOwnerTransferType {
  'TO' = 'TO',
  'FROM' = 'FROM'
}

/**
 * Optional parameters object for the {@link NftNamespace.getTransfersForOwner} method.
 */
export interface GetTransfersForOwnerOptions {
  /**
   * List of NFT contract addresses to filter mints by. If omitted, defaults to
   * all contract addresses.
   */
  contractAddresses?: string[];

  /**
   * Filter mints by ERC721 vs ERC1155 contracts. If omitted, defaults to all
   * NFTs.
   */
  tokenType?: NftTokenType.ERC1155 | NftTokenType.ERC721;

  /**
   * Optional page key from an existing {@link TransfersNftResponse} to use for
   * pagination.
   */
  pageKey?: string;
}

/**
 * Enum for representing the supported sorting orders of the API.
 *
 * @public
 */
export enum SortingOrder {
  ASCENDING = 'asc',
  DESCENDING = 'desc'
}

/** The refresh result response object returned by {@link refreshContract}. */
export interface RefreshContractResult {
  /** The NFT contract address that was passed in to be refreshed. */
  contractAddress: string;

  /** The current state of the refresh request. */
  refreshState: NftRefreshState;

  /**
   * Percentage of tokens currently refreshed, represented as an integer string.
   * Field can be null if the refresh has not occurred.
   */
  progress: string | null;
}

/**
 * The parameter field of {@link TransactionReceiptsParams}.
 *
 * @public
 */
export interface TransactionReceiptsBlockNumber {
  /** The block number to get transaction receipts for. */
  blockNumber: string;
}

/**
 * The parameter field of {@link TransactionReceiptsParams}.
 *
 * @public
 */
export interface TransactionReceiptsBlockHash {
  /** The block hash to get transaction receipts for. */
  blockHash: string;
}

/**
 * The parameters to use with the {@link CoreNamespace.getTransactionReceipts} method.
 *
 * @public
 */
export type TransactionReceiptsParams =
  | TransactionReceiptsBlockNumber
  | TransactionReceiptsBlockHash;

/**
 * Response object for a {@link CoreNamespace.getTransactionReceipts} call.
 *
 * @public
 */
export interface TransactionReceiptsResponse {
  /** A list of transaction receipts for the queried block. */
  receipts: TransactionReceipt[] | null;
}

/** An OpenSea collection's approval status. */
export enum OpenSeaSafelistRequestStatus {
  /** Verified collection. */
  VERIFIED = 'verified',
  /** Collections that are approved on open sea and can be found in search results. */
  APPROVED = 'approved',
  /** Collections that requested safelisting on OpenSea. */
  REQUESTED = 'requested',
  /** Brand new collections. */
  NOT_REQUESTED = 'not_requested'
}

/**
 * The response object for the {@link findContractDeployer} function.
 *
 * @public
 */
export interface DeployResult {
  /** The address of the contract deployer, if it is available. */
  deployerAddress?: string;

  /** The block number the contract was deployed in. */
  blockNumber: number;
}

/**
 * Method names for Alchemy's custom Subscription API endpoints.
 *
 * This value is provided in the `method` field when creating an event filter on
 * the Websocket Namespace.
 */
export enum AlchemySubscription {
  PENDING_TRANSACTIONS = 'alchemy_pendingTransactions',
  MINED_TRANSACTIONS = 'alchemy_minedTransactions'
}

/**
 * Event filter for the {@link AlchemyWebSocketProvider.on} and
 * {@link AlchemyWebSocketProvider.once} methods to use Alchemy's custom
 * `alchemy_pendingTransactions` endpoint.
 *
 * Returns the transaction information for all pending transactions that match a
 * given filter. For full documentation, see:
 * {@link https://docs.alchemy.com/reference/alchemy-pendingtransactions}
 *
 * Note that excluding all optional parameters will return transaction
 * information for ALL pending transactions that are added to the mempool.
 *
 * @public
 */
export interface AlchemyPendingTransactionsEventFilter {
  method: AlchemySubscription.PENDING_TRANSACTIONS;

  /**
   * Filter pending transactions sent FROM the provided address or array of
   * addresses.
   *
   * If a {@link AlchemyPendingTransactionsEventFilter.toAddress} is also
   * present, then this filter will return transactions sent from the
   * `fromAddress` OR transactions received by the `toAddress`.
   */
  fromAddress?: string | string[];

  /**
   * Filter pending transactions sent TO the provided address or array of
   * addresses.
   *
   * If a {@link AlchemyPendingTransactionsEventFilter.fromAddress} is also
   * present, then this filter will return transactions sent from the
   * `fromAddress` OR transactions received by the `toAddress`.
   */
  toAddress?: string | string[];

  /**
   * Whether to only include transaction hashes and exclude the rest of the
   * transaction response for a smaller payload. Defaults to false (by default,
   * the entire transaction response is included).
   *
   * Note that setting only {@link hashesOnly} to true will return the same
   * response as subscribing to `newPendingTransactions`.
   */
  hashesOnly?: boolean;
}

/**
 * Event filter for the {@link AlchemyWebSocketProvider.on} and
 * {@link AlchemyWebSocketProvider.once} methods to use Alchemy's custom
 * `alchemy_minedTransactions` endpoint.
 *
 * Returns the transaction information for all mined transactions that match the
 * provided filter. For full documentation, see:
 * {@link https://docs.alchemy.com/reference/alchemy-minedtransactions}
 *
 * Note that excluding all optional parameters will return transaction
 * information for ALL mined transactions.
 *
 * @public
 */
export interface AlchemyMinedTransactionsEventFilter {
  method: AlchemySubscription.MINED_TRANSACTIONS;

  /**
   * Address filters to subscribe to. Defaults to all transactions if omitted.
   * Limit 100 address filters. Requires a non-empty array.
   */
  addresses?: NonEmptyArray<AlchemyMinedTransactionsAddress>;

  /**
   * Whether to include transactions that were removed from the mempool.
   * Defaults to false.
   */
  includeRemoved?: boolean;

  /**
   * Whether to only include transaction hashes and exclude the rest of the
   * transaction response for a smaller payload. Defaults to false (by default,
   * the entire transaction response is included).
   */
  hashesOnly?: boolean;
}

/**
 * Address filters for {@link AlchemyMinedTransactionsEventFilter}. Requires at
 * least one of the fields to be set.
 */
export type AlchemyMinedTransactionsAddress = RequireAtLeastOne<{
  to?: string;
  from?: string;
}>;

/**
 * Alchemy's event type that extends the default {@link EventType} interface to
 * also include Alchemy's Subscription API.
 *
 * @public
 */
export type AlchemyEventType = EventType | AlchemyEventFilter;

/**
 * This type represents the Alchemy's Subscription API endpoints as event
 * filters compatible with other ethers events.
 */
export type AlchemyEventFilter =
  | AlchemyMinedTransactionsEventFilter
  | AlchemyPendingTransactionsEventFilter;

/** Options for the {@link TransactNamespace.sendPrivateTransaction} method. */
export interface SendPrivateTransactionOptions {
  /**
   * Whether to use fast-mode. Defaults to false. Please note that fast mode
   * transactions cannot be cancelled using
   * {@link TransactNamespace.cancelPrivateTransaction}. method.
   *
   * See {@link https://docs.flashbots.net/flashbots-protect/rpc/fast-mode} for
   * more details.
   */
  fast: boolean;
}

/**
 * Asset type returned when calling {@link TransactNamespace.simulateAssetChanges}.
 * Allows you to determine if the assets approved or / and transferred are
 * native, tokens or NFTs.
 */
export enum SimulateAssetType {
  /**
   * Native transfers that involve the currency of the chain the simulation is
   * run on (ex: ETH for Ethereum, MATIC for Polygon, ETH for Arbitrum).
   */
  NATIVE = 'NATIVE',
  /** ERC20 approval or transfers. */
  ERC20 = 'ERC20',
  /** ERC721 approval or transfers. */
  ERC721 = 'ERC721',
  /** ERC1155 approval or transfers. */
  ERC1155 = 'ERC1155',
  /**
   * Special contracts that don't follow ERC 721/1155.Currently limited to
   * CryptoKitties and CryptoPunks.
   */
  SPECIAL_NFT = 'SPECIAL_NFT'
}

/**
 * Change type returned when calling {@link TransactNamespace.simulateAssetChanges}.
 */
export enum SimulateChangeType {
  /**
   * Represents a transaction that approved or disapproved permissions for a
   * contract.
   *
   * APPROVE without token ID → approve all tokens
   * APPROVE without amount → approve all amount
   * APPROVE with zero amount → approval being cleared
   */
  APPROVE = 'APPROVE',

  /**
   * Represents a transaction that transferred tokens from one address to another.
   */
  TRANSFER = 'TRANSFER'
}

/**
 * The error field returned in a {@link SimulateAssetChangesResponse} if the
 * simulation failed.
 */
export interface SimulateAssetChangesError extends Record<string, any> {
  /** The error message. */
  message: string;
}

/**
 * Represents an asset change from a call to
 * {@link TransactNamespace.simulateAssetChanges}.
 */
export interface SimulateAssetChangesChange {
  /** The type of asset from the transaction. */
  assetType: SimulateAssetType;

  /** The type of change from the transaction. */
  changeType: SimulateChangeType;

  /** The from address. */
  from: string;

  /** The to address. */
  to: string;

  /**
   * The raw amount as an integer string. Only available on TRANSFER changes for
   * NATIVE and ERC20 assets, or ERC721/ERC1155 disapprove changes (field set to
   * '0').
   */
  rawAmount?: string;

  /**
   * The amount as an integer string. This value is calculated by applying the
   * `decimals` field to the `rawAmount` field. Only available on TRANSFER
   * changes for NATIVE and ERC20 assets, or ERC721/ERC1155 disapprove changes
   * (field set to '0').
   */
  amount?: string;

  /** The name of the asset transferred, if available. */
  name?: string;

  /** The symbol of the asset transferred if available. */
  symbol?: string;

  /**
   * The number of decimals used by the ERC20 token. Set to 0 for APPROVE
   * changes. Field is undefined if it's not defined in the contract and not
   * available from other sources.
   */
  decimals?: number;

  /**
   * The contract address of the asset. Only applicable to ERC20, ERC721,
   * ERC1155, NFT and SPECIAL_NFT transactions.
   */
  contractAddress?: string;

  /**
   * URL for the logo of the asset, if available. Only applicable to ERC20 transactions.
   */
  logo?: string;

  /**
   * The token id of the asset transferred. Only applicable to ERC721,
   * ERC1155 and SPECIAL_NFT NFTs.
   */
  tokenId?: string;
}

/**
 * Response object for the {@link TransactNamespace.simulateAssetChanges} method.
 */
export interface SimulateAssetChangesResponse {
  /** An array of asset changes that resulted from the transaction. */
  changes: SimulateAssetChangesChange[];
  /**
   * The amount of gas used by the transaction represented as a hex string. The
   * field is undefined if an error occurred.
   */
  gasUsed?: string;
  /** Optional error field that is present if an error occurred. */
  error?: SimulateAssetChangesError;
}

/**
 * Authority used to decode calls and logs when using the
 * {@link TransactNamespace.simulateExecution} method.
 */
export enum DecodingAuthority {
  ETHERSCAN = 'ETHERSCAN'
}

/** The input or output parameters from a {@link DecodedDebugCallTrace}. */
export interface DecodedCallParam {
  /** Value of the parameter. */
  value: string;
  /** The name of the parameter. */
  name: string;
  /** The type of the parameter.*/
  type: string;
}

/** The input parameters from a {@link DecodedLog}. */
export interface DecodedLogInput extends DecodedCallParam {
  /** Whether the log is marked as indexed in the smart contract. */
  indexed: boolean;
}

/**
 * Decoded representation of the call trace that is part of a
 * {@link SimulationCallTrace}.
 */
export interface DecodedDebugCallTrace {
  /** The smart contract method called. */
  methodName: string;
  /** Method inputs. */
  inputs: DecodedCallParam[];
  /** Method outputs. */
  outputs: DecodedCallParam[];
  /** The source used to provide the decoded call trace. */
  authority: DecodingAuthority;
}

/** The type of call in a debug call trace. */
export enum DebugCallType {
  CREATE = 'CREATE',
  CALL = 'CALL',
  STATICCALL = 'STATICCALL',
  DELEGATECALL = 'DELEGATECALL'
}

/**
 * Debug call trace in a {@link SimulateExecutionResponse}.
 */
export interface SimulationCallTrace
  extends Omit<DebugCallTrace, 'revertReason' | 'calls'> {
  /** The type of call. */
  type: DebugCallType;
  /** A decoded version of the call. Provided on a best-effort basis. */
  decoded?: DecodedDebugCallTrace;
}

/**
 * Decoded representation of the debug log that is part of a
 * {@link SimulationDebugLog}.
 */

export interface DecodedLog {
  /** The decoded name of the log event. */
  eventName: string;
  /** The decoded inputs to the log. */
  inputs: DecodedLogInput[];
  /** The source used to provide the decoded log. */
  authority: DecodingAuthority;
}

/**
 * Debug log in a {@link SimulateExecutionResponse}.
 */
export interface SimulationDebugLog {
  /** An array of topics in the log. */
  topics: string[];
  /** The address of the contract that generated the log. */
  address: string;
  /** The data included the log. */
  data: string;
  /** A decoded version of the log. Provided on a best-effort basis. */
  decoded?: DecodedLog;
}

/** Response object for the {@link TransactNamespace.simulateExecution} method. */
export interface SimulateExecutionResponse {
  /**
   * An array of traces generated during simulation that represent the execution
   * of the transaction along with the decoded calls if available.
   */
  calls: SimulationCallTrace[];

  /**
   * An array of logs emitted during simulation along with the decoded logs if
   * available.
   */
  logs: SimulationDebugLog[];
}

/**
 * Response object for the {@link TransactNamespace.sendGasOptimizedTransaction} method.
 *
 * @internal
 */
// TODO(txjob): Remove internal tag once this feature is released.
export interface GasOptimizedTransactionResponse {
  /**
   * The tracking id. This can be used to check the status of the transaction
   * via {@link TransactNamespace.getGasOptimizedTransactionStatus}.
   */
  trackingId: string;

  /* An array of the transaction hashes from submitted transactions. */
  transactionHashes: string[];
}

/**
 * Response object for the
 * {@link TransactNamespace.getGasOptimizedTransactionStatus} method.
 *
 * @internal
 */
// TODO(txjob): Remove internal tag once this feature is released.
export interface GasOptimizedTransactionStatusResponse {
  /** The status of the submitted transaction job. */
  jobStatus: GasOptimizedTransactionStatus;

  /** An array of the submitted transactions hashes that have been attempted. */
  transactionHashesAttempted: string[];

  /** The final mined transaction hash if the job was completed successfully. */
  minedTransactionHash?: string;
}

/**
 * Potential transaction job statuses for a {@link GasOptimizedTransactionResponse}
 *
 * @internal
 */
// TODO(txjob): Remove internal tag once this feature is released.
export enum GasOptimizedTransactionStatus {
  UNSPECIFIED = 'TRANSACTION_JOB_STATUS_UNSPECIFIED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  ABANDONED = 'ABANDONED'
}

/** SDK representation of a Webhook in the Notify API. */
export interface Webhook {
  /** The webhook's unique id. */
  id: string;
  /** The network the webhook is on. */
  network: Network;
  /** The type of webhook. */
  type: WebhookType;
  /** The url that the webhook sends its payload to. */
  url: string;
  /** Whether the webhook is currently active */
  isActive: boolean;
  /** The creation time of the webhook as an ISO string. */
  timeCreated: string;
  /** The signing key used to verify payloads for the webhook. */
  signingKey: string;
  /** The webhook version. All newly created webhooks default to V2. */
  version: WebhookVersion;
  /**
   * The app id of the app used for the webhook. This field is only present on
   * {@link MinedTransactionWebhook} and {@link DroppedTransactionWebhook}
   */
  appId?: string;
}

/** The version of the webhook. All newly created webhooks default to V2. */
export enum WebhookVersion {
  V1 = 'V1',
  V2 = 'V2'
}

/** The type of {@link Webhook}. */
export enum WebhookType {
  MINED_TRANSACTION = 'MINED_TRANSACTION',
  DROPPED_TRANSACTION = 'DROPPED_TRANSACTION',
  ADDRESS_ACTIVITY = 'ADDRESS_ACTIVITY',
  NFT_ACTIVITY = 'NFT_ACTIVITY',
  NFT_METADATA_UPDATE = 'NFT_METADATA_UPDATE',
  GRAPHQL = 'GRAPHQL'
}

/**
 * A Mined Transaction Webhook is used to notify your app whenever a transaction
 * sent through your API key gets successfully mined. This is useful if you want
 * to notify customers that their transaction went through.
 */
export interface MinedTransactionWebhook extends Webhook {
  type: WebhookType.MINED_TRANSACTION;
}

/**
 * A Dropped Transaction webhook is used to notify your app whenever a
 * transaction sent through your API key gets dropped. This can be useful if you
 * want to notify customers that their transactions were dropped.
 */
export interface DroppedTransactionWebhook extends Webhook {
  type: WebhookType.DROPPED_TRANSACTION;
}

/**
 * An Address Activity Webhook tracks ETH, ERC20, ERC721, and ERC1155 transfers
 * for the provided addresses. This can be used to notify your app with
 * real-time state changes when your tracked addresses send or receive tokens.
 */
export interface AddressActivityWebhook extends Webhook {
  type: WebhookType.ADDRESS_ACTIVITY;
}

/**
 * The NFT Activity Webhook tracks all ERC721 and ERC1155 activity. This can be
 * used to notify your app with real time state changes when an NFT is
 * transferred between addresses.
 */
export interface NftActivityWebhook extends Webhook {
  type: WebhookType.NFT_ACTIVITY;
}

/**
 * The NFT Metadata Update Webhook tracks all ERC721 and ERC1155 metadata updates.
 * This can be used to notify your app with real time state changes when an NFT's
 * metadata changes.
 */
export interface NftMetadataUpdateWebhook extends Webhook {
  type: WebhookType.NFT_METADATA_UPDATE;
}

/**
 * The Custom Webhook can track any event on every block (think transfers, staking,
 * minting, burning, approvals, etc.)
 * This can be used to notify your app with real time changes whenever an
 * EOA or a smart contract performs any action on-chain.
 */
export interface CustomGraphqlWebhook extends Webhook {
  type: WebhookType.GRAPHQL;
}

/** The response for a {@link NotifyNamespace.getAllWebhooks} method. */
export interface GetAllWebhooksResponse {
  /** All webhooks attached to the provided auth token. */
  webhooks: Webhook[];
  /** The total number of webhooks. */
  totalCount: number;
}

/** Options object for the {@link NotifyNamespace.getAddresses} method. */
export interface GetAddressesOptions {
  /** Number of addresses to fetch. */
  limit?: number;

  /** Page */
  pageKey?: string;
}

/** Response object for the {@link NotifyNamespace.getAddresses} method. */
export interface AddressActivityResponse {
  /** The addresses for the webhook. */
  addresses: string[];
  /** The total number of addresses. */
  totalCount: number;
  /** Optional page key used to fetch the remaining addresses. */
  pageKey?: string;
}

/** Response object for the {@link NotifyNamespace.getGraphqlQuery} method. */
export interface CustomGraphqlWebhookConfig {
  /** The graphql query for the webhook. */
  graphqlQuery: string;
}

/**
 * Params to pass in when calling {@link NotifyNamespace.createWebhook} in order
 * to create a {@link MinedTransactionWebhook} or {@link DroppedTransactionWebhook}.
 *
 * The webhook will be created on the app and network associated with the appId.
 * To find the app id of a project, go to the Alchemy Dashboard in the Apps tab.
 * After clicking on an app, the app id is the string in the URL following 'apps/'.
 *
 * This is a temporary workaround for now. We're planning on detecting the app
 * id from the provided api key directly. Stay tuned!
 */
export interface TransactionWebhookParams {
  /** The app id of the project to create the webhook on. */
  appId: string;
}

/**
 * Params to pass in when calling {@link NotifyNamespace.createWebhook} in order
 * to create a {@link NftActivityWebhook} or {@link NftMetadataUpdateWebhook}.
 */
export interface NftWebhookParams {
  /** Array of NFT filters the webhook should track. */
  filters: NftFilter[];
  /**
   * Optional network to create the webhook on. If omitted, the webhook will be
   * created on network of the app provided in the api key config.
   */
  network?: Network;
}

/**
 * Params to pass in when calling {@link NotifyNamespace.createWebhook} in order
 * to create a {@link CustomGraphqlWebhook}
 */
export interface CustomGraphqlWebhookParams {
  /** GraphQL query */
  graphqlQuery: string;
  /**
   * Optional network to create the webhook on. If omitted, the webhook will be
   * created on network of the app provided in the api key config.
   */
  network?: Network;
}

/**
 * Params to pass in when calling {@link NotifyNamespace.createWebhook} in order
 * to create a {@link AddressActivityWebhook}.
 */
export interface AddressWebhookParams {
  /** Array of addresses the webhook should activity for. */
  addresses: string[];
  /**
   * Optional network to create the webhook on. If omitted, the webhook will be
   * created on network of the app provided in the api key config.
   */
  network?: Network;
}

/** NFT to track on a {@link NftActivityWebhook} or {@link NftMetadataUpdateWebhook}. */
export interface NftFilter {
  /** The contract address of the NFT. */
  contractAddress: string;
  /**
   * The token id of the NFT to track. If this field is omitted, defaults to
   * tracking all NFTs for the provided contract address.
   */
  tokenId?: BigNumberish;
}

/** Response object for the {@link NotifyNamespace.getNftFilters} method. */
export interface NftFiltersResponse {
  /** The NFT filters on the provided webhook. */
  filters: NftFilter[];
  /** The total number of NFT filters on the webhook. */
  totalCount: number;
  /** Optional page key used to fetch the remaining filters. */
  pageKey?: string;
}

/**
 * Params object when calling {@link NotifyNamespace.updateWebhook} to mark a
 * webhook as active or inactive.
 */
export interface WebhookStatusUpdate {
  /** Whether the webhook is active. */
  isActive: boolean;
}

/**
 * Params object when calling {@link NotifyNamespace.updateWebhook} to add and
 * remove NFT filters for a {@link NftActivityWebhook}.
 */
export interface WebhookNftFilterUpdate {
  /** The filters to additionally track. */
  addFilters: NftFilter[];
  /** Existing filters to remove. */
  removeFilters: NftFilter[];
}

/**
 * Params object when calling {@link NotifyNamespace.updateWebhook} to add and
 * remove NFT filters for a {@link NftMetadataUpdateWebhook}.
 */
export interface WebhookNftMetadataFilterUpdate {
  /** The filters to additionally track. */
  addMetadataFilters: NftFilter[];
  /** Existing filters to remove. */
  removeMetadataFilters: NftFilter[];
}

/**
 * Params object when calling {@link NotifyNamespace.updateWebhook} to add and
 * remove addresses for a {@link AddressActivityWebhook}.
 */
export interface WebhookAddressUpdate {
  /** The addresses to additionally track. */
  addAddresses: string[];
  /** Existing addresses to remove. */
  removeAddresses: string[];
}

/**
 * Params object when calling {@link NotifyNamespace.updateWebhook} to replace
 * all existing addresses for a {@link AddressActivityWebhook}.
 */
export interface WebhookAddressOverride {
  /** The new addresses to track. Existing addresses will be removed. */
  newAddresses: string[];
}

/**
 * Params object when calling {@link NotifyNamespace.updateWebhook} to update a
 * {@link NftActivityWebhook}.
 */
export type NftWebhookUpdate =
  | WebhookStatusUpdate
  | RequireAtLeastOne<WebhookNftFilterUpdate>;

/**
 * Params object when calling {@link NotifyNamespace.updateWebhook} to update a
 * {@link NftMetadataUpdateWebhook}.
 */
export type NftMetadataWebhookUpdate =
  | WebhookStatusUpdate
  | RequireAtLeastOne<WebhookNftMetadataFilterUpdate>;

/**
 * Params object when calling {@link NotifyNamespace.updateWebhook} to update a
 * {@link CustomGraphqlWebhook}.
 */
export type CustomGraphqlWebhookUpdate = WebhookStatusUpdate;

/**
 * Params object when calling {@link NotifyNamespace.updateWebhook} to update a
 * {@link AddressActivityWebhook}.
 */
export type AddressWebhookUpdate =
  | WebhookStatusUpdate
  | RequireAtLeastOne<WebhookAddressUpdate>
  | WebhookAddressOverride;

/**
 * Transaction object used in {@link DebugNamespace.traceCall}, {@link TransactNamespace.simulateAssetChanges} and {@link TransactNamespace.simulateExecution}.
 */
export interface DebugTransaction {
  /** The address the transaction is directed to. */
  to?: string;
  /** The address the transaction is sent from. */
  from?: string;
  /** The gas provided for the transaction execution, as a hex string. */
  gas?: string;
  /** The gas price to use as a hex string. */
  gasPrice?: string;
  /** The value associated with the transaction as a hex string. */
  value?: string;
  /** The data associated with the transaction. */
  data?: string;
}

/**
 * Commitment level of the target block with using methods in the
 * {@link DebugNamespace}
 */
export enum CommitmentLevel {
  /**
   * Sample next block inferred by Alchemy built on top of the latest block.
   * This contains the set of transactions taken from the local mempool and
   * is a proxy for blocks that have not been mined yet.
   */
  PENDING = 'pending',
  /**
   * The most recent block in the canonical chain observed by Alchemy. Note that
   * this block may be re-orged out of the canonical chain.
   */
  LATEST = 'latest',
  /**
   * The most recent crypto-economically secure block that cannot be re-orged
   * outside of manual intervention driven by community coordination. This is
   * only available on {@link Network.ETH_GOERLI} and {@link Network.ETH_SEPOLIA}.
   */
  SAFE = 'safe',
  /**
   * The most recent secure block that has been accepted by >2/3 of validators.
   * This block is very unlikely to be re-orged. This is only available on
   * {@link Network.ETH_GOERLI} and {@link Network.ETH_SEPOLIA}.
   */
  FINALIZED = 'finalized',
  /**
   * The lowest numbered block available that is usually the first block created.
   */
  EARLIEST = 'earliest'
}

/**
 * The block identifier to specify which block to run a debug call in, used for
 * methods in the {@link DebugNamespace}.
 */
export type BlockIdentifier = string | CommitmentLevel;

/**
 * The type of tracer to use when running debug methods in the
 * {@link DebugNamespace}.
 */
export enum DebugTracerType {
  CALL_TRACER = 'callTracer',
  PRESTATE_TRACER = 'prestateTracer'
}

/**
 * Tracer used with debug methods in the {@link DebugNamespace}.
 *
 * This tracer tracks all call frames executed during a transaction, including
 * depth 0. The returned result {@link DebugCallTrace} is a nested list of call
 * frames executed as part of the call.
 *
 * Here are some things to note when using the call tracer:
 * - Calls to precompiles are also included in the result.
 * - In case a frame reverts, the field output will contain the raw return data.
 * - In case the top level frame reverts, its `revertReason` field will contain
 *   the parsed reason of revert as returned by the Solidity contract
 */
export interface DebugCallTracer {
  /** Specified type is `CALL_TRACER`. */
  type: DebugTracerType.CALL_TRACER;
  /**
   * Whether to only trace the main (top-level) calls and ignore sub-calls.
   * Defaults to `false`.
   */
  onlyTopCall?: boolean;
}

/**
 * Tracer used with debug methods in the {@link DebugNamespace}.
 *
 * This tracer replays the transaction and tracks every part of state that was
 * touched during the transaction.
 *
 * Returns a {@link DebugPrestateTrace}. This contains sufficient information to
 * create a local execution of the transaction from a custom assembled genesis
 * block.
 */
export interface DebugPrestateTracer {
  /** Specified type is `PRESTATE_TRACER`. */
  type: DebugTracerType.PRESTATE_TRACER;
  /**
   * Whether to only trace the main (top-level) calls and ignore sub-calls.
   * Defaults to `false`.
   */
  onlyTopCall?: boolean;
}

/**
 * Debug result returned when using a {@link DebugCallTracer}.
 */
export interface DebugCallTrace {
  /** The type of call: `CALL` or `CREATE` for the top-level call. */
  type: string;
  /** From address of the transaction. */
  from: string;
  /** To address of the transaction. */
  to: string;
  /** Amount of value transfer as a hex string. */
  value: string;
  /** Gas provided for call as a hex string. */
  gas: string;
  /** Gas used during the call as a hex string. */
  gasUsed: string;
  /** Call data. */
  input: string;
  /** Return data. */
  output: string;
  /** Optional error field. */
  error?: string;
  /** Solidity revert reason, if the call reverted. */
  revertReason?: string;
  /** Array of sub-calls executed as part of the original call. */
  calls?: DebugCallTrace[];
}

/**
 * Filter object used to filter logs by a specific block hash when using
 * {@link CoreNamespace.getLogs}.
 */
export interface FilterByBlockHash extends EventFilter {
  /** The specific block hash to search for logs matching the filter. */
  blockHash?: string;
}

/**
 * Filter object used to filter logs by block number range when using
 * {@link CoreNamespace.getLogs}
 */
export interface Filter extends EventFilter {
  /** The starting block (inclusive) to search for logs matching the filter. */
  fromBlock?: BlockTag;
  /** The end block (inclusive) to search for logs matching the filter.*/
  toBlock?: BlockTag;
}

/**
 * Filter object used to filter logs by when using {@link CoreNamespace.getLogs}
 */
export interface EventFilter {
  /** The address to filter by. If omitted, filters for all addresses. */
  address?: string | string[];
  /** The topics to filter by, or null to match any topics. */
  topics?: Array<string | Array<string> | null>;
}

/**
 * Debug result returned by a {@link DebugPrestateTracer}.
 *
 * The keys are the addresses of the accounts, mapped to its corresponding state.
 */
export type DebugPrestateTrace = Record<string, DebugPrestate>;

/** The */
export interface DebugPrestate {
  /** Balance of the account in wei as a hex string. */
  balance: string;
  /** Nonce */
  nonce: number;
  /** Hex-encoded bytecode. */
  code: string;
  /** Storage slots of the contract. */
  storage: Record<string, string>;
}

/**
 * Requires at least one of the properties to be set.
 *
 * Implementation copied over from
 * {@link https://learn.microsoft.com/en-us/javascript/api/@azure/keyvault-certificates/requireatleastone?view=azure-node-latest}
 */
export type RequireAtLeastOne<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> &
    Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T];

/** Requires an array with at least one value. */
export type NonEmptyArray<T> = [T, ...T[]];
